<section class="scrolly">

  <div class="del-margin-bottom">
    <div class="table-responsive">
      <table class="table table-hover" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <button class="btn btn-sm filter-btn" *ngIf="!isDetailVisible" [matMenuTriggerFor]="menu">
              <i class="fa fa-table" aria-hidden="true"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div class="card-body p-2" style="min-width: 200px;">
                <ul style="list-style: none; padding-left: 7px;">
                  <li *ngFor="let column of filterColumns">
                    <input type="checkbox" [(ngModel)]="column.show" class="m-2" (click)="$event.stopPropagation()" />
                    {{ column.title }}
                  </li>
                </ul>
                <div class="float-right mb-2">
                  <button class="btn btn-p bg-secondary">Cancel</button>
                </div>
              </div>
            </mat-menu>
            <mat-checkbox class="head-checkbox" (change)="$event ? toggleSelection() : null"
              (change)="getSelectedRows()" [checked]="selections.hasValue() && isAllSelected()"
              [indeterminate]="selections.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
              (change)="$event ? selections.toggle(row) : null" (change)="getSelectedRows()"
              [checked]="selections.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="misc">
          <th mat-header-cell *matHeaderCellDef>Invoices</th>
          <td mat-cell *matCellDef="let element" class="misc-cell">
            <span class="col-name">{{
              element.customer
              ? element.customer.customer_name
              : element?.depot?.depot_name
              }}</span>
            <span class="col-price">{{
              numberFormatWithSymbol(element.grand_total)
              }}</span>
            <span class="col-date">{{ element.order_date }}</span>
            <span class="col-code">{{ element.order_number }}</span>
          </td>
        </ng-container> -->
        <ng-container matColumnDef="create_date">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Sync Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="created_at" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('created_at')"
                placeholder="Enter Date" type="date">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date :"d MMM y h:mm a" }}</td>
        </ng-container>
        <ng-container matColumnDef="mobile_created_at">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Creation Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="mobile_created_at" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('mobile_created_at')"
                placeholder="Enter Date" type="date">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element?.mobile_created_at | date :"d MMM y h:mm a" }}</td>
        </ng-container>
        <ng-container matColumnDef="invoice_date">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Invoice Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_date" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_date')"
                placeholder="Enter Invoice Date" type="date">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.invoice_date }}</td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Invoice#" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_number" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_number')"
                placeholder="Enter Invoice#" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element.invoice_number }}</td>
        </ng-container>
        <ng-container matColumnDef="branch_plant_code">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Branch Plant" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="branch_plant_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('branch_plant_code')"
                placeholder="Enter Branchplant code" type="text">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.storagelocation?.code }}</td>
        </ng-container>
        <ng-container matColumnDef="order_number">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Order No#" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="order_number" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('order_number')"
                placeholder="Enter Order No" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element.order?.order_number }}</td>
        </ng-container>

        <ng-container matColumnDef="customer_code">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Code#" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="customer_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_code')"
                placeholder="Enter Customer Code#" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element?.user?.customer_info?.customer_code}}</td>
        </ng-container>


        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="customer_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_name')"
                placeholder="Enter Customer Name" type="text">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{
            element.user
            ? element?.user?.firstname + " " + element?.user?.lastname
            : element?.depot?.depot_name
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="channel_name">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Channel" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="channel_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('channel_name')"
                placeholder="Enter Channel Name" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element" (click)="openDetailView(element)">
            <ng-container>
              {{
                element.customer_category?.customer_category_name
              }}
            </ng-container>
          </td>
        </ng-container>
        <!--  Route Code-->
        <ng-container matColumnDef="route_code">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Route Code" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="route_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('route_code')"
                placeholder="Enter Route Code" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.route?.route_code }}</td>
        </ng-container>
        <!--  End Route code -->
        <!-- Route Name -->
        <ng-container matColumnDef="route_name">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Route Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="route_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('route_name')"
                placeholder="Enter Route Name" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.route?.route_name }}</td>
        </ng-container>
        <!-- End Route Name -->
        <!-- Salesman Code -->
        <ng-container matColumnDef="salesman_code">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Salesman Code" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="salesman_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_code')"
                placeholder="Enter Salesman Code" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.salesman_user?.salesman_info?.salesman_code }}</td>
        </ng-container>
        <!-- End Salesman Code   -->
        <!-- Salesman Name -->
        <ng-container matColumnDef="salesman_name">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Salesman Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="salesman_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_name')"
                placeholder="Enter Salesman Name" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container>
              {{
              element?.salesman_user == null ? '' :
              element.salesman_user?.firstname + " " + element.salesman_user?.lastname
              }}
            </ng-container>
          </td>
        </ng-container>
        <!-- End Salesman Name -->

        <ng-container matColumnDef="due">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Delivery Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_due_date" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_due_date')"
                placeholder="Enter Customer Delivery Date" type="date">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.order?.delivery_date }}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            {{ numberFormatWithSymbol(element.grand_total) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="approval">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Approval" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="current_stage" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('current_stage')">
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Reject">Reject</mat-option>
                <mat-option value="Pending">Pending</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.current_stage;">
              <span [style.color]="orderStatusColor(element.current_stage)">
                {{ getOrderStatusValue(element.current_stage) }}
              </span>
            </ng-container>

          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Status" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="approval_status" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('approval_status')">
                <mat-option value="Requested">Requested</mat-option>
                <mat-option value="Truck Allocated">Truck Allocated </mat-option>
                <mat-option value="Completed">Completed </mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.approval_status;">
              <span>
                {{ element.approval_status }}
              </span>
            </ng-container>

          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="payment_status">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Payment Status" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('')">
                <mat-option value="1">Pending</mat-option>
                <mat-option value="0">Active</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{getPaymentStatus(element)}}
          </td>
        </ng-container> -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef style="display: none;">Detail</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-between;">
              <strong>
                {{
                element.user
                ? element?.user?.firstname + " " + element?.user?.lastname
                : element?.depot?.depot_name
                }}</strong>
              <span>
                {{ numberFormatWithSymbol(element.grand_total) }}</span>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <span style="color: #2485e8;">{{ element.invoice_number }}</span>
              <span style="color: #2485e8;">
                {{ element.invoice_due_date | date }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ERP_status">
          <th class="erp-status" mat-header-cell *matHeaderCellDef>
            <app-column-filter title="ERP Status" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="erp_status" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('erp_status')">
                <mat-option value="Not Posted">Not Posted</mat-option>
                <mat-option value="Posted">Posted</mat-option>
                <mat-option value="Failed">Fail</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.oddo_post_id==null && element.odoo_failed_response==null? 'Not Posted': ''}}
            {{ element.oddo_post_id && (element.odoo_failed_response==null || element.odoo_failed_response=='') ?
            'Posted': ''}}
            {{ element.odoo_failed_response ? 'Failed': ''}}

            <i *ngIf="element.odoo_failed_response" class="fa fa-info-circle" aria-hidden="true"
              (click)="filterOdooMessageData(element.odoo_failed_response);isOdooMessageOpen=true">
            </i>

            <i class="fa fa-refresh" style="margin-left: 4px;"
              *ngIf="element.oddo_post_id==null && element.current_stage ==='Approved'"
              (click)="postOdoo(element);isOdooMessageOpen=true"></i>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" [ngClass]="{ 'misc-row': isDetailVisible }"
          (click)="openDetailView(row);isOdooMessageOpen=false"></tr>
      </table>

    </div>
    <mat-paginator [hidden]="apiResponse.pagination?.total_records < 10" (page)="onPageFired($event)"
      [length]="apiResponse.pagination?.total_records" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]">
    </mat-paginator>
  </div>
</section>

<ng-template #dialogRef let-data>
  <div class="card">
    <div class="card-header pt-4">
      <span class="h6 font-weight-bold">Odoo Failed</span>
      <a [mat-dialog-close]="true" class="closeicon settingclose"><i class="os-icon os-icon-x"></i></a>
    </div>
    <div class="card-body">
      <p>{{data}}</p>
    </div>
  </div>
</ng-template>
