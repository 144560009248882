<section>
    <div class="card-header">
        <span class="h6">Manage Salesman Supervisor</span>
        <button class="dialog-close-btn" mat-dialog-close>
            <i class="fa fa-times fa-lg" aria-hidden="true"></i>
        </button>
    </div>
    <div class="form-container" *ngIf="showForm">
        <form [formGroup]="salesOrganisationForm">
            <div class="container">
                <div class="row mb-2">
                    <div class="col-4">Supervisor Name</div>
                    <div class="col-8">
                        <input type="text" [ngClass]="{
                            'invalid-input':
                            salesOrganisationFormControl.invalid &&
                              (salesOrganisationFormControl.dirty ||
                                salesOrganisationFormControl.touched)
                          }" class="form-control form-control-sm" type="text"
                            [formControl]="salesOrganisationFormControl" />
                    </div>
                </div>
            </div>

            <div class="save-btn">
                <button mat-button class="mat-inherit-line-height btn-green" (click)="saveSalesOrganisationData()">
                    Save
                </button>
                <button mat-button class="mat-inherit-line-height" (click)="showForm = false">
                    Cancel
                </button>
            </div>
        </form>
    </div>

    <div class="categories-title">
        <h4>
            Salesman Supervisor
            <span class="add-new-label"><button (click)="addNewSalesOrganisation()"
                    *ngIf="salesOrganisationData.length > 0" [type]="'create'">
                    <i class="fa fa-plus-circle"></i>Add New Salesman Supervisor
                </button></span>
        </h4>
    </div>
    <div class="empty-list text-center mt-4" *ngIf="isLoaded">
        <p class="text-muted" *ngIf="salesOrganisationData.length == 0">
            No data available
        </p>
        <button [type]="'create'" mat-button class="add-new-btn" *ngIf="salesOrganisationData.length == 0 && !showForm"
            (click)="addNewSalesOrganisation()">
            Add New Salesman Supervisor
        </button>
    </div>
    <div class="sales-org-holder">
        <app-tree-container (applyData)="applySalesOrganisation($event)" (editData)="editSalesOrganisation($event)"
            (deleteData)="deleteSalesOrganisation($event)" [treeData]="salesOrganisationData">
        </app-tree-container>
    </div>

    <div class="cancel-btn">
        <button mat-button class="mat-inherit-line-height height-40" mat-dialog-close>
            Cancel
        </button>
    </div>
</section>