<form [formGroup]="form" novalidate>
    <div class="row pt-4">
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Date Range</label>
                <div class="col-sm-9" >
                    <div class="row">
                        <div class="col">
                            <input type="date" formControlName="startdate" class="form-control form-control-sm" placeholder="Start Date">
                        </div>
                        <div class="col-1 p-0">
                            &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                        </div>
                        <div class="col">
                            <input type="date" formControlName="enddate" class="form-control form-control-sm" placeholder="End Date">
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Customer</label>
                <div class="col-sm-9">
                    <app-multiautocomplete (selectionchanged)="selectionchangedCustomer()" [options]="customer"
                        [control]="customersFormControl" text="" id="id"
                        objectName="user-root" param1="firstname" param2="lastname">
                    </app-multiautocomplete>
                    <!-- <mat-select multiple class="form-control form-control-sm" formControlName="customer">
                        <mat-option *ngFor="let item of customer" [value]="item.id">
                          {{ item.firstname }} {{ item.lastname }}
                        </mat-option>
                      </mat-select> -->
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Merchandiser</label>
                <div class="col-sm-9">
                    <app-multiautocomplete (selectionchanged)="selectionchangedMerchandiser()" [options]="merchandisers"
                        [control]="merchandisersFormControl" text="" id="user_id" objectName="user"
                        param1="firstname" param2="lastname">
                    </app-multiautocomplete>
                    <!-- <mat-select multiple class="form-control form-control-sm" formControlName="merchandiser">
                        <mat-option *ngFor="let item of merchandisers" [value]="item.user?.id">
                          {{ item.user?.firstname }} {{ item.user?.lastname }}
                        </mat-option>
                      </mat-select> -->
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Brand</label>
                <div class="col-sm-9">
                    <app-multiautocomplete (selectionchanged)="selectionchangedBrand()" [options]="brands"
                        [control]="brandsFormControl" id="id" text="brand_name">
                    </app-multiautocomplete>
                    <!-- <mat-select multiple class="form-control form-control-sm" formControlName="brand">
                        <mat-option *ngFor="let item of brands" [value]="item.id">
                          {{ item.brand_name }}
                        </mat-option>
                      </mat-select> -->
                </div>
            </div>
        </div>
    </div>
</form>