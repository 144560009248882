<form [formGroup]="form" novalidate>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Date Range</label>
                <div class="col-sm-9" >
                    <div class="row">
                        <div class="col">
                            <input type="date" formControlName="startdate" class="form-control form-control-sm" placeholder="Start Date">
                        </div>
                        <div class="col-1 p-0">
                            &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                        </div>
                        <div class="col">
                            <input type="date" formControlName="enddate" class="form-control form-control-sm" placeholder="End Date">
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Merchandiser</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="salesman">
                        <mat-option *ngFor="let item of salesman" [value]="item.id">
                          {{ item.firstname }} {{ item.lastname }}
                        </mat-option>
                      </mat-select>
                </div>
            </div>
        </div>
    </div>
</form>