<section class="makeScrollable">
  <form [formGroup]="Form">
    <div class="card-header">
      <span class="h6" style="font-size: 20px;"><b>Add {{label}}</b></span>
      <button class="dialog-close-btn" (click)="close()" mat-dialog-close>
        <i class="fa fa-times fa-lg" aria-hidden="true"></i>
      </button>
    </div>
    <div class="form-container">  
        <div class="">
          <div class="row mb-2">
            <div class="col-md-6">
              <input class="form-control form-control-sm" (change)="searchCustomer()" type="month" formControlName="date" />
            </div>
            <div class="col-md-6">
              <app-multiautocomplete isSingle="true" [options]="dataSource"
                [control]="Form.get('customer')"
                text="" id="id"
                objectName="user" param1="firstname" param2="lastname"
                placeholder="Search Customer"
                selectionchanged="genterateDebitNote()">
              </app-multiautocomplete>
            </div>
          </div>
        </div>
    </div>
    <!--<div class="categories-title">
      <h4>
        {{label}}
      </h4>
    </div>
     <div class="channel-holder">
      <div class=" data-body">
        <table class="table table-hover" mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef>
                    
                </th>
                <td mat-cell *matCellDef="let row">
                    <app-radio-button
                        [config]="{ label: '', value: row.id, name: 'customer_id', control: Form.get('customer') }">
                    </app-radio-button>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Customer Name </th>
              <td mat-cell *matCellDef="let element"> {{element.user.firstname}} {{element.user.lastname}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" ></tr>
          </table>
      </div>
      <div [ngClass]="{ hidden: getPaginatorValue(dataSource.data.length) }">
        <mat-paginator pageSize="5" [pageSizeOptions]="[5, 10]"></mat-paginator>
      </div>
    </div> -->
    <div class="modal-footer">
      <button mat-button class="btn btn-sm btn-p" [disabled]="Form.get('customer').invalid" (click)="genterateDebitNote()" mat-dialog-close>
        Generate Debit Note
      </button>&nbsp;
      <button class="btn btn-sm btn-secondary" (click)="close()">Cancel</button>
    </div>
  </form>
  </section>
  