
<div class="card-header">
	<a class="dialog-close-btn" mat-dialog-close><i class="fa fa-times fa-lg" aria-hidden="true"></i></a>
	<span class="h6">Manage Channel</span>
</div>
<div class="form-container" *ngIf="showForm">
	<form [formGroup]="channelFormGroup">
		<div class="">
			<div class="">
				
				<div class="row mb-2">
					<div class="col-md-4">Channel Name</div>
					<div class="col-md-8">
						<input
							[ngClass]="{'invalid-input': channelNameFormControl.invalid && (channelNameFormControl.dirty || channelNameFormControl.touched)}"
							class="form-control form-control-sm" type="text" [formControl]="channelNameFormControl">
						<div *ngIf="channelNameFormControl.invalid && (channelNameFormControl.dirty || channelNameFormControl.touched)"
							class="text-danger">
							<div *ngIf="channelNameFormControl.errors.required">
								<small>Channel name is required.</small>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-md-4">Parent Channel</div>
					<div class="col-md-8">
						<select class="form-control form-control-sm" [formControl]="parentIdFormControl">
							<option *ngFor="let channel of treeControl.dataNodes" [value]="channel.id">
								<span class="text-muted" *ngFor="let i of counter(channel.level)">&nbsp; &bull; </span>
								{{channel.name}}
							</option>
						</select>
					</div>
				</div>
				
			</div>
			
			<div class="save-btn float-right">
				<button class="btn btn-sm btn-light mr-2" (click)="showForm = false">Cancel</button>
				<button class="btn btn-sm btn-p" (click)="saveChannelData()"><span
						*ngIf="isEdit; else savebutton">Update</span>
					<ng-template #savebutton>Save</ng-template>
				</button>
			</div>
		</div>
	</form>
</div>
<div class="tree-container">
	<a class="add-new-btn" *ngIf="!showForm" (click)="addChannel()"><i
			class="fa fa-plus-circle mr-2 text-info" aria-hidden="true"></i>Add New
		Channel</a>

	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<!-- This is the tree node template for leaf nodes -->
		<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
			<!-- use a disabled button to provide padding for tree leaf -->
			<button mat-icon-button disabled></button>
			<span class="mat-tree-text"></span>{{node.name}}
			<div class="tree-menu ml-auto" (click)="$event.stopPropagation()">
				<div class="">
					<a class="pl-2 pr-2" (click)="applyChannel(node)">Apply this Channel</a>
					<a class="pl-2 pr-2 border-left" (click)="editChannel(node)">Edit</a>
					<a class="pl-2 pr-2 border-left" (click)="deleteChannel(node.uuid)">Delete</a>
				</div>
			</div>
		</mat-tree-node>
		<!-- This is the tree node template for expandable nodes -->
		<mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle>
			<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
				<mat-icon class="mat-icon-rtl-mirror">
					{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
				</mat-icon>
			</button>
			<span class="mat-tree-text"></span>{{node.name}}
			<div class="tree-menu ml-auto" (click)="$event.stopPropagation()">
				<div class="">
					<a class="pl-2 pr-2" (click)="applyChannel(node)">Apply this Channel</a>
					<a class="pl-2 pr-2 border-left" (click)="editChannel(node)">Edit</a>
					<a class="pl-2 pr-2 border-left" (click)="deleteChannel(node.uuid)">Delete</a>
				</div>
			</div>
		</mat-tree-node>
	</mat-tree>


</div>