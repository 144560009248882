<div class="card" style="width: 550px;">
    <div class="card-header pt-4">
        <span class="h6 font-weight-light">Edit Region</span>
        <a (click)="close()"><i class="fa fa-times fa-lg text-secondary float-right" aria-hidden="true"></i></a>
    </div>
    <form [formGroup]="regionEditForm" (ngSubmit)="onSubmit()">
    <div class="card-body">
        <div class="">
            <div class="row mb-4">
                <div class="col-md-4">Region Code</div>
                <div class="col-md-8">
                    <input class="form-control form-control-sm" type="text" formControlName="region_code">
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-4">Region Name</div>
                <div class="col-md-8">
                    <input class="form-control form-control-sm" type="text" formControlName="region_name">
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-4">Country</div>
                <div class="col-md-8">
                    <select class="form-control form-control-sm" formControlName="country_id">
                        <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                      </select>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="d-inline float-right">
            <button class="btn btn-sm btn-light mr-2">Cancel</button>
            <button class="btn btn-sm btn-p">Save</button>
        </div>
    </div>
    </form>
</div>