<form [formGroup]="form" novalidate>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">{{(domain == 'merchandising' || domain == 'presales-prodmobiato.nfpc.net'
                    ? 'Merchandiser Code' : 'Salesman Code' )}}</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="salesman_code" class="form-control form-control-sm"
                        placeholder="Code">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">First Name</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="firstname" class="form-control form-control-sm"
                        placeholder="First Name">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Last Name</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="lastname" class="form-control form-control-sm"
                        placeholder="Last Name">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="email" class="form-control form-control-sm" placeholder="Email">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Route</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="route">
                        <mat-option *ngFor="let item of routes" [value]="item.id">
                            {{ item.route_name }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="domain !== 'presales-prodmobiato.nfpc.net' && domain !== 'merchandising'">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Role</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="role_id">
                        <mat-option *ngFor="let item of roles" [value]="item.id">
                            {{ item.role_name }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Mobile</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="mobile" class="form-control form-control-sm"
                        placeholder="Mobile">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Status</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="current_stage">
                        <mat-option *ngFor="let item of statusList" [value]="item.id">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</form>