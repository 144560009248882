<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Order Date</label>
        <!-- <div class="col-sm-9" style="display:flex;">
                    <input type="date" formControlName="startdate" class="form-control form-control-sm" placeholder="Start Date">
                   &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                   <input type="date" formControlName="enddate" class="form-control form-control-sm" placeholder="End Date">
                </div> -->
        <div class="col-sm-9">
          <div class="row">
            <div class="col">
              <input type="date" formControlName="startdate" class="form-control form-control-sm"
                placeholder="Start Date" />
            </div>
            <div class="col-1 p-0">
              &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
            </div>
            <div class="col">
              <input type="date" formControlName="enddate" class="form-control form-control-sm"
                placeholder="End Date" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Delivery Date </label>
        <div class="col-sm-9" style="display: flex;">
          <input type="date" formControlName="delivery_start_date" class="form-control form-control-sm"
            placeholder="Start Date" />
          &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
          <input type="date" formControlName="delivery_end_date" class="form-control form-control-sm"
            placeholder="End Date" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Order NO</label>
        <div class="col-sm-9">
          <input type="text" formControlName="order_no" class="form-control form-control-sm" placeholder="Order NO" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Customer Name</label>
        <div class="col-sm-9">
          <input type="text" formControlName="customerName" class="form-control form-control-sm"
            placeholder="Customer  Name" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Customer LPO</label>
        <div class="col-sm-9">
          <input type="text" formControlName="customer_lpo" class="form-control form-control-sm"
            placeholder="Customer  LPO" />
        </div>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Delivery Date</label>
        <div class="col-sm-9">
          <input type="date" formControlName="delivery_date" class="form-control form-control-sm"
            placeholder="Delivery Date" />
        </div>
      </div>
    </div> -->
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Item</label>
        <div class="col-sm-9">
          <!-- <app-multiautocomplete isSingle="true" objectName="items-root" [options]="items"
            (selectionchanged)="selectionchangedItems()" [control]="itemsFormControl" text="item_name" id="id"
            param1="item_code" param2="item_name">
          </app-multiautocomplete> -->

          <angular2-multiselect [data]="items" [formControl]="itemsFormControl"
            [settings]="settings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
            <c-search>
              <ng-template>
                <input type="text" (input)="onSearch($event)" placeholder="Search Items"
                  style="border: none;width: 100%; height: 100%;outline: none;" />
              </ng-template>
            </c-search>
            <c-item>
              <ng-template let-item="item">
                <label style="color: #333;width: 150px;">{{item.itemName}}</label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="orderCreatedUser?.length>0">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">User Created</label>
        <div class="col-sm-9">
          <app-multiautocomplete isSingle="true" objectName="user-root" [options]="orderCreatedUser"
            (selectionchanged)="selectionchangedorderCreatedUser()" [control]="customersFormControl" text="firstname"
            id="id" param1="firstname" param2="lastname">
          </app-multiautocomplete>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="storageLocation?.length>0">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Brachplant</label>
        <div class="col-sm-9">
          <app-multiautocomplete isSingle="true" objectName="code-root" [options]="storageLocation"
            (selectionchanged)="selectionchangedstorageLocation()" [control]="branchplantsFormControl" text="name"
            id="id" param1="code" param2="name">
          </app-multiautocomplete>
        </div>
      </div>
    </div>


    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Approval Status</label>
        <div class="col-sm-9">
          <mat-select class="form-control form-control-sm" formControlName="current_stage">
            <mat-option *ngFor="let item of statusList" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Order Status</label>
        <div class="col-sm-9">
          <mat-select class="form-control form-control-sm" formControlName="approval_status">
            <mat-option *ngFor="let item of orderStatusList" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</form>