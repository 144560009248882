<ng-container *ngIf="control.invalid && (control.dirty || control.touched)">
    <ng-container *ngIf="control.errors.required"
        >Field is required!</ng-container
    >
    <ng-container *ngIf="control.errors.email && !control.errors.required"
        >Email is invalid!</ng-container
    >
    <ng-container *ngIf="control.errors.maxlength"
        >Field required Length:
        {{ control.errors.maxlength.requiredLength }}</ng-container
    >
    <ng-container *ngIf="control.errors.minlength"
        >Field required Length:
        {{ control.errors.minlength.requiredLength }}</ng-container
    >
    <ng-container *ngIf="control.errors.passwordNotMatch"
        >Password not matched
        </ng-container
    >
</ng-container>
