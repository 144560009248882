<section>
	<div class="card-header">
	  <span class="h6">Manage Category</span>
	  <button class="dialog-close-btn" mat-dialog-close><i class="fa fa-times fa-lg" aria-hidden="true"></i></button>
	</div>
	<div class="form-container" *ngIf="showForm">
	  <form [formGroup]="categoryForm">

		<div class="">
		  <div class="row mb-2">
			<div class="col-md-4">Category Name</div>
			<div class="col-md-8">
			  <input
				[ngClass]="{'invalid-input': categoryFormControl.invalid && (categoryFormControl.dirty || categoryFormControl.touched)}"
				class="form-control form-control-sm"
				type="text" [formControl]="categoryFormControl">
			  <div *ngIf="categoryFormControl.invalid && (categoryFormControl.dirty || categoryFormControl.touched)" class="text-danger">
				<div><small>Category name is required.</small></div>
			  </div>
			</div>
		  </div>
		  <div class="row mb-2">
			<div class="col-md-4">Parent Category</div>
			<div class="col-md-8">
			  <app-tree-dropdown
				class="category-dropdown"
				[isManageable]="false"
				[dataSource]="categoryData"
				[dataService]="categoryProvider()"
				(whenItemSelected)="categorySelected($event)"
                [selectFormControl]="parentFormControl"
				[placeHolder]="'Search a parent category'"
				ngDefaultControl>
			  </app-tree-dropdown>
			</div>
		  </div>
		</div>

		<div class="save-btn">
		  <button mat-button class="mat-inherit-line-height btn-green" (click)="saveCategoryData()">Save</button>
		  <button mat-button class="mat-inherit-line-height" (click)="showForm = false">Cancel</button>
		</div>

	  </form>
	</div>

	<div class="categories-title">
	  <h4>Categories <span class="add-new-label"><button *ngIf="categoryData.length > 0" (click)="addNewCategory()"><i class="fa fa-plus-circle"></i>Add New Category</button></span></h4>
	</div>
	<div class="empty-list text-center mt-4" *ngIf="isLoaded">
		<p class="text-muted" *ngIf="categoryData.length == 0">No Category available</p>
		<button mat-button class="add-new-btn" *ngIf="categoryData.length == 0 && !showForm" (click)="addNewCategory()">Add
			New Category</button>
	</div>
	<div class="category-holder scrolly">
	  <app-tree-container
		(applyData)="applyCategory($event)"
		(editData)="editCategory($event)"
		(deleteData)="deleteCategory($event)"
		[treeData]="categoryData">
	  </app-tree-container>
	</div>

	<div class="cancel-btn">
	  <button mat-button class="mat-inherit-line-height height-40" mat-dialog-close>Cancel</button>
	</div>
  </section>
