<div class="card h-100">
    <div class="card-header">
        <span class="h6 text-secondary">Bulk update</span>
    </div>
    <div class="card-body">
    
    </div>
    <div class="card-footer">
        <div class="float-right">
            <button class="btn btn-p mr-2">Update Fields</button>
            <button class="btn btn-sm btn-light" (click)="close()">Cancel</button>
        </div>
    </div>
</div>
